:root {
  --real-light-gray: #D5D2D1;
  --light-gray: #c2bdb2;
  --medium-gray: #6D685E;
  --dark-gray: #403D38;
  --depot-green: #40A340;
  --depot-red: #CC5757;
}

* {
  background-color: var(--real-light-gray);
}

a {
  color: var(--dark-gray);
  font-weight: bold;
}

.header {
  width: 500px;
  color: var(--dark-gray);
  margin-bottom: 28px;
  font-weight: bold;
}

.everything {
  /* padding-top: 84px;
  padding-left: 177px; */
  padding: 70px 125px;
  color: var(--dark-gray);
  font-family: 'Arimo';
}

.middle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 900px;
}

.search {
  flex-basis: 1;
  flex-shrink: 0;
  width: 340px;
  margin-right: 56px;
}

.search-bar {
  position: relative;
  height: 40px;
  display: flex;
  border: 1px solid var(--dark-gray);
}

.search-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  height: 2px;
  width: 18px;
  height: 18px;
}

.search-input {
  flex: 1;
  padding: 0 12px 0 36px;
  border: none;
  font-family: Courier;
}

.search-input:focus {
  outline: none;
}

.search-bar:focus-within {
  outline: 1px solid var(--dark-gray);
}

.search-result {
  border: 1px solid var(--dark-gray);
  padding: 16px 24px;
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-result-header {
  font-family: Courier;
  font-weight: bold;
  margin-bottom: 10px;
}

.search-result-header.small-text {
  font-size: 16px;
}

.search-result-header.medium-text {
  font-size: 22px;
}

.search-result-header.large-text {
  font-size: 28px;
}

.portfolio-box {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--dark-gray);
  margin-bottom: 6px;
  padding: 10px 24px;
  min-width: 400px;
  max-width: 680px;
  min-height: 160px;
}

.portfolio-header {
  font-size: 24px;
  font-weight: bold;
  color: var(--medium-gray);
  margin-bottom: 12px;
}

.empty-portfolio-message {
  color: var(--medium-gray);
  font-size: 12px;
  letter-spacing: 1px;
}

.portfolio-row {
  border: 1px solid var(--dark-gray);
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 24px;
  column-gap: 12px;
  height: 60px;
}

.portfolio-row-word {
  font-family: 'Courier';
  font-weight: bold;
  margin-bottom: 14px;
  font-size: 17px;
}

.portfolio-row-balance {
  font-weight: bold;
  font-size: 14px;
}

.portfolio-row-lower-half {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.buy-and-sell-buttons {
  display: flex;
  flex-direction: row;
  width: 116px;
  align-items: center;
  justify-content: space-between;
  font-size: 10px;
}

.portfolio-trade-button {
  width: 50px;
}

.trade-modal {
  margin-right: 12px;
  border: 1px solid var(--dark-gray);
  position: fixed;
  padding: 24px;
  width: 450px;
  top: 25%;
  left: 0;
  right: 0;
  margin: auto;
}

.trade-modal .xButton {
  position: absolute;
}

.trade-header {
  font-size: 40px;
  text-align: center;
  margin-top: 18px;
  margin-bottom: 30px;
}

.trade-word {
  font-family: Courier;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.trade-modal-button {
  float: right;
  width: 76px;
  height: 36px;
  font-size: 18px;
}

.button {
  border: 1px solid var(--dark-gray);
  color: white;
  font-weight: bold;
  background-color: var(--medium-gray);
  cursor: pointer;
}

.button.grayed-out {
  background-color: var(--light-gray);
  cursor: default;
}

.button.grayed-out:hover {
  background-color: var(--light-gray);
  color: white;
}

.connect-wallet-container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.connect-wallet-button {
  padding: 10px;
  margin: 150px auto;
}

.search-result-trade-button {
  width: 70px;
  height: 36px;
}

.search-result-buttons {
  display: flex;
  flex-direction: row;
  width: 180px;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
}

.button:hover {
  color: var(--dark-gray);
  background-color: white;
}

.trade-quantity {
  width: 60px;
  height: 30px;
  margin-right: 10px;
  border: 1px solid var(--dark-gray);
  text-align: right;
  padding: 4px 10px;
}

.trade-quantity:focus {
  outline: 1px solid var(--dark-gray);
}

.trade-price-and-currency {
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  width: 150px;
}

/* .trade-price {
} */

.currency-dropdown {
  flex-shrink: 0;
  flex-basis: 60px;
  height: 30px;
  margin-left: 10px;
  font-size: 12px;
}

.currency-dropdown-option {
  height: 30px;
}

.trade-price-getter {
  display: flex;
  flex-direction: row;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.dropdown {
  border: 1px solid var(--dark-gray);
  cursor: pointer;
}

.dropdown.closed {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 2px 0;
}

.dropdown-option {
  border: 1px solid var(--dark-gray);
  margin: -1px;
  padding: 2px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-option:hover {
  background-color: var(--medium-gray);
  color: white;
}

.xButton {
  cursor: pointer;
}

.message-modal {
  margin-right: 12px;
  border: 1px solid var(--dark-gray);
  position: fixed;
  padding: 30px;
  width: 220px;
  top: 30%;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
}

.message-modal .xButton {
  position: absolute;
  margin: -18px;
}

.message-modal p {
  line-height: 1.5;
}

.error-message {
  border: 1px solid var(--dark-gray);
  padding: 12px;
  width: 300px;
  height: 90px;
  display: flex;
  flex-direction: row;
  position: absolute;
}

.error-message .xButton {
  position: absolute;
}

.error-message p {
  margin: auto;
}

.no-wallet-detected {
  margin: 50px auto;
  width: 400px;
  line-height: 1.5;
  font-size: 18px;
}